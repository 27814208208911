/* eslint-disable react/no-unescaped-entities */
import React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Container from "../Container"
import { useTheme } from "@emotion/react"

const Headline = (props) => {
  const { data } = props
  const theme = useTheme();
  return (
    <Box
      position={"relative"}
      sx={{
        backgroundColor: theme.palette.alternate.main,
        backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 30%, ${theme.palette.alternate.main} 100%)`,
        marginTop: -13,
        paddingTop: 13
      }}
    >
      <Container>
        <Box>
          <Box>
            <Typography variant="h3" gutterBottom>
              {data.title}
            </Typography>
            <Typography variant="h3" color={"primary"} fontWeight={700}>
              {data.subtitle}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Headline
