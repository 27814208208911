/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Container from "../Container";
import {white} from "tailwindcss/colors";

const Features = (props) => {
    const theme = useTheme();
    const {data} = props;
    return (
        <Container>
            <Box marginTop={0}>
                <Box marginBottom={4}>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'medium',
                        }}
                        gutterBottom
                        color={'primary.main'}
                        align={'center'}
                    >
                        {data.label}
                    </Typography>
                    <Typography
                        variant={'h4'}
                        gutterBottom
                        align={'center'}
                        sx={{fontWeight: 700}}
                    >
                        {data.title}
                    </Typography>
                    <Typography
                        variant={'h6'}
                        component={'p'}
                        color={'text.secondary'}
                        align={'center'}
                    >
                        {data.subtitle}
                    </Typography>
                </Box>
                <Box>
                    <Grid container spacing={4}>
                        {data.features.map((item, i) => (
                            <Grid item xs={12} sm={6} md={4} key={i}>
                                <Box
                                    width={1}
                                    height={1}
                                    data-aos={'fade-up'}
                                    data-aos-delay={i * 100}
                                    data-aos-offset={100}
                                    data-aos-duration={600}
                                >
                                    <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        alignItems={'flex-start'}
                                    >
                                        <Box
                                            component={Avatar}
                                            width={50}
                                            height={50}
                                            marginBottom={2}
                                            bgcolor={theme.palette.primary.main}
                                            color={theme.palette.background.paper}
                                        >
                                            <svg
                                                height={24}
                                                width={24}
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                            >
                                                <path fill={white}
                                                    d={item.svgd}>
                                                </path>
                                            </svg>
                                        </Box>
                                        <Typography
                                            variant={'h6'}
                                            gutterBottom
                                            sx={{fontWeight: 700}}
                                        >
                                            {item.title}
                                        </Typography>
                                        <Typography color="text.secondary">
                                            {item.description}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

export default Features;
