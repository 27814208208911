import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import { withPrefix } from "gatsby"
import { getStrapiMedia } from "../../utils/media"
import Container from "../Container"

const Hero = (props) => {
  const { data } = props;
  const theme = useTheme()
  const { mode } = theme.palette;

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  })

  return (
    <Container>
      <Box>
        <Grid container spacing={4}>
          <Grid item container xs={12} md={6} alignItems={"center"}>
            <Box data-aos={isMd ? "fade-right" : "fade-up"}>
              <Box marginBottom={2}>
                <Typography
                  variant="h3"
                  color="text.primary"
                  sx={{ fontWeight: 700 }}
                >
                  {data.title}
                </Typography>
                <Typography
                  variant="h3"
                  color={"primary"}
                  sx={{ fontWeight: 700 }}
                >
                  {data.label}
                </Typography>
              </Box>
              <Box marginBottom={3}>
                <Typography variant="h6" component="p" color="text.secondary">
                  {data.description}
                </Typography>
              </Box>
              <Grid
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "stretched", sm: "flex-start" }}
              >
                {data.buttons.map((button, i) =>
                  <Grid item key={i}>
                    <Box
                      component={Button}
                      variant={button.variant}
                      color={button.type}
                      size="large"
                      target={button.newTab ? "blank" : ""}
                      href={withPrefix(button.url)}
                      marginTop={{ xs: 2, sm: 0 }}
                      marginLeft={{ sm: 2 }}
                      fullWidth={!isMd}
                    >
                      {button.text}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            container
            alignItems={"center"}
            justifyContent={"center"}
            xs={12}
            md={6}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
              <Box
                component={LazyLoadImage}
                height={1}
                width={1}
                src={mode === "light" ? getStrapiMedia(data.picture.bright.url) : getStrapiMedia(data.picture.dark.url)}
                alt="..."
                effect="blur"
                boxShadow={3}
                borderRadius={2}
                maxWidth={600}
              />
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default Hero
