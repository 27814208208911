import React, {useState} from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

import Container from "../Container"
import emailjs from 'emailjs-com'
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Markdown from "react-markdown";

const Contact = (props) => {

    const {data} = props;

    const LeftSide = () => {
        return (
            <Box>
                <Typography variant={'h4'} gutterBottom sx={{fontWeight: 700}}>
                    Quiénes somos<br/><br/>
                </Typography>
                <Typography component={"p"} color={"text.secondary"}>
                    <Markdown children={"Ingenieros aeronáuticos de formación, consultores de negocio y desarrolladores de vocación, somos un equipo ágil y con amplia experiencia en la creación de software y su aplicación en la gestión empresarial."}/>
                    <br/>
                    <Markdown children={"Nuestro objetivo principal es hacer accesible la tecnología de las grandes corporaciones a las pequeñas y medianas empresas. Sabemos que la digitalización tiene cabida en cada pequeño rincón y queremos ayudarle a crecer de nuestra mano."}/>
                    <br/><br/>
                    <Markdown children={"Teléfono: +34 628 28 98 53"}/>
                    <Markdown children={"Email: contacto@aerodrop.es"}/>
                </Typography>
            </Box>
        )
    }

    const RightSide = () => {

        const [name, setName] = useState('');
        const [email, setEmail] = useState('');
        const [phone, setPhone] = useState('');
        const [message, setMessage] = useState('');
        const [emailSent, setEmailSent] = useState(false);

        const submit = () => {
            if (name && email && message) {
                const serviceId = 'service_mfnk5ve';
                const templateId = 'template_c5l2xyc';
                const userId = 'oC2X8TazshXXfJYvm';
                const templateParams = {
                    name,
                    email,
                    message,
                    phone,
                };

                emailjs.send(serviceId, templateId, templateParams, userId)
                    .then(response => console.log(response))
                    .then(error => console.log(error));

                setName('');
                setEmail('');
                setMessage('');
                setPhone('');
                setEmailSent(true);
            } else {
                alert('Please fill in all fields.');
            }
        };

        return (
            <Box justifyContent={"flex-start"}>
                <Box marginBottom={2}>
                    <Box marginBottom={2}>
                        <Typography variant="h4" align="center">
                            <strong>Póngase en contacto con nosotros</strong><br/><br/>
                        </Typography>
                        <Typography variant="h6" color="textSecondary" align="center">
                            Si quiere conocernos más o busca más información sobre nuestra actividad, no dude en contactarnos.
                        </Typography>
                    </Box>
                    <Box marginBottom={2}>
                        <TextField
                            placeholder="Nombre completo"
                            label="Nombre completo *"
                            variant="outlined"
                            size="medium"
                            name="fullname"
                            fullWidth
                            onChange={e => setName(e.target.value)}
                            type="text"
                            value={name}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <TextField
                            placeholder="E-mail"
                            label="E-mail *"
                            variant="outlined"
                            size="medium"
                            name="email"
                            fullWidth
                            onChange={e => setEmail(e.target.value)}
                            type="email"
                            value={email}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <TextField
                            placeholder="Teléfono"
                            label="Teléfono"
                            variant="outlined"
                            size="medium"
                            name="phone"
                            fullWidth
                            onChange={e => setPhone(e.target.value)}
                            type="text"
                            value={phone}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <TextField
                            placeholder="Mensaje"
                            label="Mensaje *"
                            variant="outlined"
                            name="message"
                            fullWidth
                            onChange={e => setMessage(e.target.value)}
                            multiline
                            rows={4}
                            value={message}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <Button
                            size="large"
                            variant="contained"
                            type="submit"
                            color="primary"
                            onClick={submit}
                        >
                            Enviar
                        </Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Container>
            <Box
                sx={{
                    width: 1,
                    height: 1,
                    overflow: "hidden"
                }}
            >
                <Container paddingX={0} paddingY={0} maxWidth={{sm: 1, md: 1236}}>
                    <Box
                        display={"flex"}
                        flexDirection={{xs: "column", md: "row"}}
                        position={"relative"}
                    >
                        <Box width={1} order={{xs: 2, md: 1}}>
                            <Container>
                                <LeftSide/>
                            </Container>
                        </Box>
                        <Box width={1} order={{xs: 2, md: 1}}>
                            <Container>
                                <RightSide/>
                            </Container>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Container>
    )
}

export default Contact
