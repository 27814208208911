import React from "react"
import { useTheme } from "@mui/material/styles"
import Card from "@mui/material/Card"
import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Avatar from "@mui/material/Avatar"
import Grid from "@mui/material/Grid"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import Container from "../Container"
import { getStrapiMedia } from "../../utils/media"

const Team = (props) => {
  const { data } = props
  const theme = useTheme()

  return (
    <Container>
      <Box>
        <Box marginBottom={4}>
          <Typography
            sx={{
              textTransform: "uppercase",
              fontWeight: 700
            }}
            gutterBottom
            color={"text.secondary"}
            align={"center"}
          >
            {data.label}
          </Typography>
          <Typography
            variant="h4"
            align={"center"}
            gutterBottom
            sx={{
              fontWeight: 700,
              marginTop: theme.spacing(1)
            }}
          >
            {data.title}
          </Typography>
          <Typography variant="h6" align={"center"} color={"text.secondary"}>
            {data.subtitle}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {data.members.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box
                width={1}
                height={1}
                component={Card}
                boxShadow={0}
                variant={"outlined"}
                bgcolor={"alternate.main"}
              >
                <CardContent sx={{ padding: 3 }}>
                  <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                    <ListItemAvatar sx={{ marginRight: 3 }}>
                      <Avatar
                        src={getStrapiMedia(item.image.url)}
                        variant={"rounded"}
                        sx={{ width: 100, height: 100, borderRadius: 2 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary={item.name}
                      secondary={item.position}
                      primaryTypographyProps={{ variant: "h6", fontWeight: 700 }}
                      secondaryTypographyProps={{ variant: "subtitle1" }}
                    />
                  </ListItem>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  )
}

export default Team
