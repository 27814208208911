import React from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Container from "../Container"

const ContactMail = () => {
  return (
    <Container>
      <Box>
        <Box marginBottom={4}>
          <Typography
            fontWeight={700}
            variant={"h4"}
            align={"center"}
            gutterBottom
          >
            Ready to growth your business?
          </Typography>
          <Typography
            variant={"h6"}
            component={"p"}
            color={"textSecondary"}
            align={"center"}
          >
            Your website is fully responsive so visitors can view your content
            from their choice of device.
          </Typography>
        </Box>
        <Box maxWidth={600} margin={"0 auto"}>
          <Box
            component={"form"}
            noValidate
            autoComplete="off"
            sx={{
              "& .MuiInputBase-input.MuiOutlinedInput-input": {
                bgcolor: "background.paper"
              }
            }}
          >
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              alignItems={{ xs: "center", md: "flex-start" }}
              justifyContent={{ xs: "center" }}
            >
              <Box
                flex={"1 1 auto"}
                component={TextField}
                label="Enter your email"
                variant="outlined"
                color="primary"
                fullWidth
                height={54}
                sx={{
                  maxWidth: 422
                }}
              />
              <Box
                component={Button}
                variant="contained"
                color="primary"
                size="large"
                height={54}
                marginTop={{ xs: 2, md: 0 }}
                marginLeft={{ md: 2 }}
              >
                Request a demo
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default ContactMail
