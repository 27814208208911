import React, {useState} from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from "../Container";
import {getStrapiMedia} from "../../utils/media";

const mock = [
    {
        title: 3,
        subtitle:
            '3 + herramientas de automatización y optimización implementadas.',
        suffix: '+',
    },
    {
        title: 10,
        subtitle:
            '10 + plugins estandar ya desarrollados.',
        suffix: '+',
    },
    {
        title: 80,
        subtitle: '80% de las necesidades de una PYME están cubiertas por módulos estandar.',
        suffix: '%',
    },
];

const Hub = (props) => {
    const theme = useTheme();
    const { data } = props;

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [viewPortEntered, setViewPortEntered] = useState(false);
    const setViewPortVisibility = (isVisible) => {
        if (viewPortEntered) {
            return;
        }

        setViewPortEntered(isVisible);
    };

    return (
        <Container>
            <Box marginTop={0}>
                <Grid container spacing={4}>
                    <Grid
                        item
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                        xs={12}
                        md={6}
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                    >
                        <Box
                            component={LazyLoadImage}
                            height={1}
                            width={1}
                            src={getStrapiMedia(data.image.url)}
                            alt="..."
                            effect="blur"
                            boxShadow={3}
                            borderRadius={2}
                            maxWidth={600}
                        />
                    </Grid>
                    <Grid item container xs={12} md={6} alignItems={'center'}>
                        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
                            <Box marginBottom={2}>
                                <Typography
                                    variant="h4"
                                    color="text.primary"
                                    sx={{fontWeight: 700}}
                                >
                                    {data.title + ' '}
                                    <Typography
                                        color={'primary'}
                                        component={'span'}
                                        variant={'inherit'}
                                    >
                                        {data.subtitle}
                                    </Typography>
                                </Typography>
                            </Box>
                            <Box marginBottom={4}>
                                <Typography variant="h6" component="p" color="text.secondary">
                                    {data.description}
                                </Typography>
                            </Box>
                            <Box>
                                <Grid container spacing={2}>
                                    {data.KPIs.map((item, i) => (
                                        <Grid key={i} item xs={12} md={4}>
                                            <Typography variant="h3" gutterBottom>
                                                <Box fontWeight={600}>
                                                    <VisibilitySensor
                                                        onChange={(isVisible) =>
                                                            setViewPortVisibility(isVisible)
                                                        }
                                                        delayedCall
                                                    >
                                                        <CountUp
                                                            redraw={false}
                                                            end={viewPortEntered ? item.number : 0}
                                                            start={0}
                                                            suffix={item.suffix}
                                                        />
                                                    </VisibilitySensor>
                                                </Box>
                                            </Typography>
                                            <Typography color="text.secondary" component="p">
                                                {item.subtitle}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>

    );
};

export default Hub;
