/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import useMediaQuery from "@mui/material/useMediaQuery"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Container from "../../Container"
import Markdown from "react-markdown"

const TextText = (props) => {
  const { data } = props
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  })

  return (
    <Container>
      <Box>
        <Grid container spacing={4} direction={isMd ? "row" : "column"}>
          {data.textcols.map((item, i) => (
            <Grid
              item
              container
              alignItems={"center"}
              justifyContent="center"
              xs={12}
              md={6}
            >
              <Box>
                <Typography variant={"h4"} gutterBottom sx={{ fontWeight: 700 }}>
                  {item.title}
                </Typography>
                <Typography component={"p"} color={"text.secondary"}>
                  <Markdown children={item.text} />
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  )
}

export default TextText
