import React from 'react';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Container from "../Container"
import { getStrapiMedia } from "../../utils/media"


const PartnersHero = ( props ) => {
  const { data } = props;
  return (
    <Container>
      <Grid container spacing={0}>
        {data.partners.map((item, index) => (
          <Grid
            item
            container
            key={index}
            xs={4}
            direction={index < 3 ? 'row' : 'row-reverse'}
          >
            <Grid item xs={6}>
              <Avatar
                src={getStrapiMedia(item.logo.url)}
                sx={{
                  width: { xs: 60, md: 80 },
                  height: { xs: 60, md: 80 },
                  padding: 2,
                  boxShadow: 4,
                  marginTop: 1,
                  bgcolor: 'background.paper',
                }}
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PartnersHero;
