import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Container from "../Container"
import { getStrapiMedia } from "../../utils/media"

export const mock = [
  {
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Microsoft_Office_logo_%282019%E2%80%93present%29.svg/512px-Microsoft_Office_logo_%282019%E2%80%93present%29.svg.png",
    name: "Microsoft Office"
  },
  {
    logo: "https://www.logo.wine/a/logo/Amazon_Web_Services/Amazon_Web_Services-Logo.wine.svg",
    name: "AWS"
  },
  {
    logo: "https://assets.maccarianagency.com/svg/logos/dropbox.svg",
    name: "Dropbox"
  },
  {
    logo: "https://assets.maccarianagency.com/svg/logos/google-drive.svg",
    name: "Google Drive"
  },
  {
    logo: "https://img.stackshare.io/service/4908/superset2.png",
    name: "Superset"
  },
  {
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/1024px-Google_%22G%22_Logo.svg.png",
    name: "Google Suite"
  }
]

const Partners = (props) => {
  const theme = useTheme()
  const { data } = props

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  })

  return (
    <Container>
      <Box marginTop={0}>
        <Grid container spacing={4}>
          <Grid item container xs={12} md={6} alignItems={"center"}>
            <Box data-aos={isMd ? "fade-right" : "fade-up"}>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "medium"
                }}
                gutterBottom
                color={"primary"}
              >
                {data.label}
              </Typography>
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
                {data.title}
              </Typography>
              <Typography variant="h6" component="p" color="text.secondary">
                {data.subtitle}
              </Typography>
              <Box marginTop={2}>
                <Button
                  href={data.link.url}
                  size={"large"}
                  endIcon={
                    <Box
                      component={"svg"}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      width={24}
                      height={24}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </Box>
                  }
                >
                  {data.link.text}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item container spacing={2} xs={12} md={6}>
            {data.partners.map((item, i) => (
              <Grid
                item
                xs={4}
                key={i}
                data-aos="fade-up"
                data-aos-delay={i * 100}
                data-aos-offset={100}
                data-aos-duration={600}
              >
                <Box
                  display={"block"}
                  width={1}
                  height={1}
                  sx={{
                    transition: "all .2s ease-in-out",
                    "&:hover": {
                      transform: `translateY(-${theme.spacing(1 / 2)})`
                    }
                  }}
                >
                  <Card>
                    <CardContent
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 3
                      }}
                    >
                      <Box
                        component={LazyLoadImage}
                        height={50}
                        width={50}
                        src={getStrapiMedia(item.logo.url)}
                        alt={item.name}
                        effect="blur"
                      />
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default Partners
