/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from "../../Container";
import Markdown from "react-markdown"
import { getStrapiMedia } from "../../../utils/media"

const TextImage = ( props ) => {
    const {data} = props;
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <Container>
            <Box>
                <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
                    <Grid item container alignItems={'center'} xs={12} md={6}>
                        <Box>
                            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
                                {data.textcol.title}
                            </Typography>
                            <Typography component={"p"} color={"text.secondary"}>
                                <Markdown children={data.textcol.text} />
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        md={6}
                    >
                        <Box maxWidth={500} width={1}>
                            <Box
                                component={'img'}
                                src={
                                    getStrapiMedia(data.image.url)
                                }
                                width={1}
                                height={1}
                                sx={{
                                    filter:
                                        theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default TextImage;
