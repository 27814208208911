import React, {useEffect} from "react"
import Hero from "./sections/Hero"
import {useCookies} from "react-cookie"
import {navigate} from "gatsby-link"
import {useLocation} from "@reach/router"
import {useTheme} from "@emotion/react";
import Features from "./sections/Features";
import Hub from "./sections/Hub";
import Partners from "./sections/Partners";
import Header from "./sections/Header";
import TextImage from "./sections/2Col/TextImage";
import TextText from "./sections/2Col/TextText";
import Team from "./sections/Team";
import Contact from "./sections/Contact";
import Headline from "./sections/Headline"
import PartnersHero from "./sections/PartnersHero"
import ContactMail from "./sections/ContactMail"
import Stories from "./sections/Stories"

// Map Strapi section to section components
const sectionComponents = {
    "sections.hero": Hero,
    "sections.cabecera": Header,
    "sections.features": Features,
    "sections.hub": Hub,
    "sections.partners": Partners,
    "sections.2-col-text-image": TextImage,
    "sections.2-col-text-text": TextText,
    "sections.team": Team,
    "sections.contact": Contact,
    "sections.headline": Headline,
    "sections.partners-hero" : PartnersHero,
    "sections.stories":Stories,
    "sections.contact-mail":ContactMail,

}

const PreviewModeBanner = ({location}) => {
    return (
        <div className="py-4 bg-red-600 text-red-100 font-semibold uppercase tracking-wide">
            <div className="container">
                Preview mode is on.{" "}
                <button
                    className="underline"
                    onClick={() => {
                        // The cookie will be deleted by a useEffect in the Section component
                        navigate("/", {state: {prevPath: location.pathname}})
                    }}
                >
                    Turn off
                </button>
            </div>
        </div>
    )
}

// Display a section individually
const Section = ({sectionData, articulos}) => {
    // Prepare the component
    const SectionComponent = sectionComponents[sectionData.strapi_component || sectionData.__component]

    if (!SectionComponent) {
        // No matching component for this page section
        return null
    }

    // Display the section
    return <SectionComponent data={sectionData}  articulos={articulos}/>
}

// Display the list of sections_old
const Sections = ({sections, articulos}) => {
    const location = useLocation()
    // Ignore unused destructured variable
    // eslint-disable-next-line
    const [cookies, _, removeCookie] = useCookies(["strapiPreview"])

    useEffect(() => {
        // The preview cookie is deleted when state.prevPath exists on location
        if (location.state && location.state.prevPath) {
            removeCookie("strapiPreview", {
                path: "/",
                secure: process.env.NODE_ENV === "production",
                sameSite: "Strict"
            })
        }
    }, [location, removeCookie])

    const previewModeIsEnabled = process.env.GATSBY_PREVIEW_SECRET &&
        cookies.strapiPreview === process.env.GATSBY_PREVIEW_SECRET
    return (

        sections.map((section, i) => (
            <Section
                sectionData={section}
                articulos={articulos}
                key={`${section.strapi_component}${(section.id + i)}`}
            />
        ))
    )
}

export default Sections
